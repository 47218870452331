<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <notification class="is-info">
        <p>
          <span class="has-text-weight-medium">Estamos trabajando.</span>
          Esta sección pronto estará disponible.
        </p>
      </notification>
      <tiles>
        <profile-update-form class="tile is-child"/>
        <card-component title="Perfil" icon="account" class="tile is-child">
          <user-avatar class="image has-max-width is-aligned-center"/>
          <hr>
          <b-field label="Nombre">
            <b-input :value="userName" custom-class="is-static" readonly/>
          </b-field>
          <hr>
          <b-field label="E-mail">
            <b-input :value="userEmail" custom-class="is-static" readonly/>
          </b-field>
        </card-component>
      </tiles>
      <password-update-form v-if="false" />
    </section>
  </Layout>
</template>

<script>
import { mapState } from 'vuex'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import ProfileUpdateForm from '@/components/Profile/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/Profile/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'
import Layout from '@/components/Layout'
import Notification from '@/components/Notification'

export default {
  name: 'Profile',
  components: {
    UserAvatar,
    Tiles,
    PasswordUpdateForm,
    ProfileUpdateForm,
    TitleBar,
    CardComponent,
    Layout,
    Notification
  },
  computed: {
    titleStack () {
      return [
        'Inicio',
        'Perfil'
      ]
    },
    ...mapState([
      'userName',
      'userEmail'
    ])
  }
}
</script>
