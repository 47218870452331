<template>
  <card-component title="Actualizar Contraseña" icon="lock">
    <form>
      <b-field label="Contraseña Actual">
        <b-input
          name="password_current"
          type="password"
          v-model="form.password_current"
          required
          autcomplete="current-password"
          disabled
        />
      </b-field>
      <hr>
      <b-field label="Nueva Contraseña">
        <b-input name="password" type="password" v-model="form.password" required autocomplete="new-password" disabled />
      </b-field>
      <b-field label="Confirmar Contraseña">
        <b-input name="password_confirmation" type="password" v-model="form.password_confirmation" required
          autocomplete="new-password" disabled/>
      </b-field>
      <hr>
      <b-button disabled type="is-primary" :loading="isLoading">
        Actualizar
      </b-button>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null
      }
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.$buefy.snackbar.open({
          message: 'Actualizado',
          queue: false
        }, 500)
      })
    }
  }
}
</script>
